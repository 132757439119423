<template>
  <div class="list-user">
    <confirm-action-modal
      v-if="isConfirmModalOpen"
      :object-to-act-upon="selectedList"
      :text="confirmModalText"
      @close="isConfirmModalOpen = false"
      @confirm="onCloseModal"
    />

    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>
    <div class="list-user-header">
      <PageHeader
        v-model="query"
        :has-actions="isAdmin || isCollab || isClient"
        :has-back="true"
        :has-search="true"
        :tabs="tabs"
        title="Utilisateurs"
        @back="goBack"
        @change-tab="changeTab"
      >
        <template #switch>
          <md-switch v-model="pagination"
            >{{ $t("common.showByPage") }}
          </md-switch>
        </template>

        <md-button
          v-if="currentTab !== 'tab-trashed'"
          :class="{
            'md-primary': !isJEP,
            'md-alternate': isJEP,
          }"
          class="md-raised header-action header-action-icon"
          @click="$router.push('/dashboard/setting/users/create')"
        >
          <MeepIconActionAdd class="app-icon-stroke-inverted" />
        </md-button>

        <md-button
          v-if="isAdmin && selectedList.length > 1"
          :class="{
            'md-primary': !isJEP,
            'md-alternate': isJEP,
          }"
          class="md-raised header-action header-action-icon"
          @click="
            currentTab === 'tab-trashed'
              ? showConfirmUsersModal('delete')
              : showConfirmUsersModal('trash')
          "
        >
          <MeepIconActionDelete class="app-icon" />
        </md-button>

        <md-button
          v-if="currentTab !== 'tab-trashed'"
          :class="{
            'md-primary': !isJEP,
            'md-alternate': isJEP,
          }"
          class="md-raised header-action header-action-icon"
          to="/dashboard/setting/import"
        >
          <MeepIconActionImport class="app-icon" />
        </md-button>

        <md-button
          v-if="currentTab !== 'tab-trashed'"
          :class="{
            'md-primary': !isJEP,
            'md-alternate': isJEP,
          }"
          class="md-raised header-action header-action-icon"
          @click="exportUsers"
        >
          <MeepIconActionExport class="app-icon" />
        </md-button>

        <md-button
          v-if="currentTab === 'tab-trashed'"
          :class="{
            'md-primary': !isJEP,
            'md-alternate': isJEP,
          }"
          class="md-raised header-action header-action-icon"
          @click="showConfirmUsersModal('restore')"
        >
          <MeepIconActionRestore class="app-icon" />
        </md-button>
      </PageHeader>
    </div>

    <div class="list-user-content page-layout">
      <LoadingCard v-if="isLoading" />

      <template v-else>
        <md-card v-if="newUsers.length > 0" class="new-users">
          <div class="md-layout md-gutter header">
            <div class="md-layout-item md-size-45">{{ $t("form.email") }}</div>

            <div class="md-layout-item md-size-55">{{ $t("form.status") }}</div>
          </div>

          <div
            v-for="user of newUsers"
            :key="user.data.email"
            class="md-layout md-gutter"
          >
            <div class="md-layout-item md-size-45">
              {{ user.data.email }}
            </div>

            <div class="md-layout-item md-size-55">
              <md-progress-bar
                v-if="!user.status && !user.messageError"
                md-mode="indeterminate"
              />
              {{ user.status || user.messageError }}
            </div>
          </div>
        </md-card>

        <div v-if="showAll" class="list-user--all">
          <!-- La liste des utilisateurs -->
          <list-user
            :pagination="pagination"
            :query="query"
            :selected-list="selectedList"
            :previousPosition="previousPosition"
            @change-list="changeList"
            @change-action="changeConfirmationText"
          />

          <!-- La liste des utilisateurs doit être approuvée par l'administrateur-->
          <list-unverified-user
            v-if="isAdmin"
            :pagination="pagination"
            :selected-list="selectedList"
            @change-list="changeList"
            @change-action="changeConfirmationText"
          />
        </div>

        <list-user
          v-if="showOnlyUser"
          :pagination="pagination"
          :query="query"
          :selected-list="selectedList"
          @change-list="changeList"
          @change-action="changeConfirmationText"
        />

        <list-unverified-user
          v-if="showUnverified"
          :pagination="pagination"
          :selected-list="selectedList"
          @change-list="changeList"
          @change-action="changeConfirmationText"
        />

        <list-trash-user
          v-if="showTrash"
          :pagination="pagination"
          :query="query"
          :selected-list="selectedList"
          @change-list="changeList"
          @change-action="changeConfirmationText"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Fuse from "fuse.js";

import usersModel from "../../model/users";
import vueXlsxTable from "@deevotechvn/vue-xlsx-table";
import { mapActions, mapGetters } from "vuex";
import ConfirmActionModal from "../../components/modal/confirm-action";
import PageHeader from "@/components/PageHeader";
import MeepIconActionAdd from "@/components/icons/MeepIconActionAdd.vue";
import MeepIconActionDelete from "@/components/icons/MeepIconActionDelete.vue";
import MeepIconActionImport from "@/components/icons/MeepIconActionImport.vue";
import MeepIconActionExport from "@/components/icons/MeepIconActionExport.vue";
import MeepIconActionRestore from "@/components/icons/MeepIconActionRestore.vue";
import Banner from "@/components/Banner";
import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";

import ListUnverifiedUser from "./ListUnverifiedUser.vue";
import ListTrashUser from "./ListTrashUser.vue";
import ListUser from "./ListUser.vue";
import {
  adminUserTemplate,
  buildObject,
  downloadExcel,
  userTemplate,
} from "@/services/excel";

import LoadingCard from "@/components/LoadingCard";
import { adaptUser } from "@/services/util";
import {
  LIST_USERS,
  LIST_UNVERIFIED_USERS,
} from "@/store/modules/users/users.actions";

Vue.use(vueXlsxTable, { rABS: false });

export default {
  name: "ListUserPage",

  components: {
    LoadingCard,
    PageHeader,
    Banner,
    MeepIconSettings,
    MeepIconActionAdd,
    MeepIconActionDelete,
    MeepIconActionImport,
    MeepIconActionExport,
    ConfirmActionModal,
    ListUnverifiedUser,
    ListTrashUser,
    ListUser,
    MeepIconActionRestore,
  },

  data() {
    return {
      isLoading: false,
      currentTab: "tab-all",
      query: "",
      users: [],
      usersCount: 0,
      unverifiedUserCount: 0,
      trashUsers: [],
      newUsers: [],
      confirmationContext: "trash",
      selectedList: [],
      isConfirmModalOpen: false,
      showImportUserDialog: false,
      pagination: this.$route.query.showAll ? false : true,
      previousPosition: null,
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isCollab", "isClient", "isJEP", "isJEPA"]),
    ...mapGetters("users", [LIST_USERS, LIST_UNVERIFIED_USERS]),

    listParameters() {
      const defaultParameters = [
        {
          name: this.$t("list-user.fullName"),
          key: "fullName",
          class: this.isJEPA
            ? "list-user__fullName alt-item"
            : "list-user__fullName primary-item",
        },
        {
          name: this.$t("form.email"),
          key: "email",
        },
        {
          name: this.$t("form.status"),
          key: "scope",
          format: this.$$filters.formatScope,
        },
      ];

      if (this.isAdmin) {
        defaultParameters.push({
          name: "",
          isIcon: true,
          iconFormat: () => "delete",
          iconClass: () => "",
          clickable: true,
        });
      }

      return defaultParameters;
    },

    tabs() {
      if (this.isAdmin) {
        return [
          {
            id: "tab-all",
            label: `Tous (${this.usersCount + this.unverifiedUserCount})`,
          },
          {
            id: "tab-user",
            label: `Utilisateurs (${this.usersCount})`,
          },
          {
            id: "tab-unverified",
            label: `En attente d’approbation (${this.unverifiedUserCount})`,
          },
          {
            id: "tab-trashed",
            label: "Corbeille",
          },
        ];
      }

      return [];
    },

    filter() {
      if (this.query === "") {
        return this.users;
      }

      const fuse = new Fuse(this.users, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        keys: ["firstname", "lastname", "email"],
      });

      return fuse.search(this.query).map((item) => item.item);
    },

    canChangeUserStatus() {
      return this.isAdmin || this.isClient || this.isCollab;
    },

    templateLink() {
      return this.canChangeUserStatus
        ? "/templates/meep_users_template.xlsx"
        : "/templates/meep_users_template_normal.xlsx";
    },

    showAll() {
      return this.currentTab === "tab-all" || this.currentTab === undefined;
    },

    showTrash() {
      return this.currentTab === "tab-trashed" && this.isAdmin;
    },

    showOnlyUser() {
      return this.currentTab === "tab-user" && this.isAdmin;
    },

    showUnverified() {
      return this.currentTab === "tab-unverified" && this.isAdmin;
    },

    confirmModalText() {
      const isSingle = this.selectedList.length === 1;

      switch (this.confirmationContext) {
        case "trash": {
          return {
            header: isSingle
              ? this.$t("list-user.delete-modal-title-single")
              : this.$t("list-user.delete-modal-title-plural"),
            body: (items) => {
              return isSingle
                ? this.$t("list-user.delete-modal-body-single", {
                    fullName: items[0].fullName,
                  })
                : this.$t("list-user.delete-modal-body-plural", {
                    count: items.length,
                  });
            },
            question: this.$t("list-user.modal-confirm-cta"),
          };
        }

        case "restore": {
          return {
            header: isSingle
              ? this.$t("list-user.restore-modal-title-single")
              : this.$t("list-user.restore-modal-title-plural"),
            body: (items) => {
              return isSingle
                ? this.$t("list-user.restore-modal-body-single", {
                    fullName: items[0].fullName,
                  })
                : this.$t("list-user.restore-modal-body-plural", {
                    count: items.length,
                  });
            },
            question: this.$t("list-user.modal-confirm-cta"),
          };
        }

        default: {
          return {
            header: isSingle
              ? this.$t("list-user.permanently-delete-modal-title-single")
              : this.$t("list-user.permanently-delete-modal-title-plural"),
            body: (items) => {
              return isSingle
                ? this.$t("list-user.permanently-delete-modal-body-single", {
                    fullName: items[0].fullName,
                  })
                : this.$t("list-user.permanently-delete-modal-body-plural", {
                    count: items.length,
                  });
            },
            question: this.$t("list-user.modal-confirm-cta"),
          };
        }
      }
    },
  },

  watch: {
    [LIST_USERS]() {
      this.users = [...this[LIST_USERS]];
      this.usersCount = this[LIST_USERS].length;
    },

    [LIST_UNVERIFIED_USERS]() {
      this.unverifiedUserCount = this[LIST_UNVERIFIED_USERS].length;
    },
    pagination() {
      if (!this.pagination && !this.$route.query.showAll) {
        this.$router.replace({
          name: "user-list",
          query: {
            showAll: true,
          },
        });
      } else if (this.pagination && this.$route.query.showAll) {
        this.$router.replace({
          name: "user-list",
        });
      }
    },
  },

  methods: {
    ...mapActions("users", [
      "deleteVerifyItem",
      "deleteItem",
      "trashItem",
      "restoreItem",
    ]),

    goBack() {
      window.history.back();
    },

    exportUsers() {
      try {
        this.isLoading = true;
        downloadExcel(
          this.users,
          this.canChangeUserStatus ? adminUserTemplate : userTemplate,
          this.$t("list-user.export-option"),
          "users.xlsx"
        );
      } catch (err) {
        this.$toasted.global.AppError({
          message: err,
        });
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },

    showConfirmUsersModal(text) {
      this.isConfirmModalOpen = true;
      this.confirmationContext = text;
    },

    async handleSelectedFile(convertedUsers) {
      this.newUsers = [];

      const columns = {
        lastname: {
          label: "Nom",
          required: true,
          type: String,
        },
        firstname: {
          label: "Prénom",
          required: true,
          type: String,
        },
        email: {
          label: "Email",
          required: true,
          type: String,
        },
        adresse: {
          label: "Adresse",
          required: false,
          type: String,
        },
        ville: {
          label: "Ville",
          required: false,
          type: String,
        },
        codepostal: {
          label: "Code postal",
          required: false,
          type: String,
        },
        telfixe: {
          label: "Numéro de téléphone fixe",
          required: false,
          type: String,
        },
        telportable: {
          label: "Numéro de téléphone portable",
          required: false,
          type: String,
        },
        statut: {
          label: "Statut",
          required: true,
          type: String,
        },
      };

      this.showImportUserDialog = false;

      this.newUsers = convertedUsers.body.map((user) =>
        buildObject(user, columns)
      );

      let e = this;
      await Promise.all(
        this.newUsers.map(async (user, index) => {
          if (user.isValid) {
            try {
              const data = await usersModel.create(user.data);
              e.$set(
                e.newUsers[index],
                "status",
                "L'utilisateur a bien été créé"
              );
              e.users.push(adaptUser(data));
            } catch (err) {
              e.$set(e.newUsers[index], "status", err.msg);
            }
          } else {
            let message = `Ligne ${index + 1}: `;
            user.errors.forEach((error) => {
              message += error + ", ";
            });
            user.messageError = message.slice(0, message.length - 2);
          }
        })
      );
    },

    changeTab(tabId) {
      this.currentTab = tabId;
      this.selectedList = [];
    },

    changeConfirmationText(action) {
      this.confirmationContext = action;
      this.isConfirmModalOpen = true;
    },

    changeList(list) {
      this.selectedList = list;
    },

    onSelect(items) {
      this.selectedList = items;
    },

    onCloseModal() {
      try {
        this.isLoading = true;
        switch (this.confirmationContext) {
          case "trash":
            this.trashItem(this.selectedList);
            break;
          case "restore":
            this.restoreItem(this.selectedList);
            break;
          case "delete":
            this.deleteItem(this.selectedList);
            break;
          case "deleteUnverified":
            this.deleteVerifyItem(this.selectedList);
        }
      } catch (err) {
        Vue.toasted.global.AppError({
          message: err.msg,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  beforeRouteEnter(_, from, next) {
    const origins = ["user-detail", "user-update"];
    if (origins.includes(from.name)) {
      next((vm) => (vm.previousPosition = from.params.id));
    } else {
      next()
    }
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app {
  .xlsx-button {
    background-color: var(--bg-primary);
  }
  .new-users {
    width: 100%;
    padding: 5px;

    .header {
      font-weight: bold;
    }

    .md-layout {
      padding: 8px;
    }

    .md-indeterminate {
      margin-top: 10px;
    }
  }

  .md-menu-content {
    .list-user {
      &__fullName {
        font-weight: bold;
      }

      &--all,
      &--trash {
        width: 100%;
      }

      &__menu-content {
        .md-list-item-button {
          .md-list-item-content {
            white-space: normal;
            padding: 0;
            margin: 0 12px;
          }
        }
      }

      &__download-btn {
        cursor: pointer;

        .md-list-item-content {
          white-space: normal;
          padding: 0;
          margin: 0 12px;
        }

        &:hover {
          background: var(
            --md-theme-default-divider-on-background,
            rgba(0, 0, 0, 0.12)
          );
        }
      }
    }
  }

  .list-user {
    &-content {
      &.page-layout {
        height: 100%;
      }
    }
  }

  .import-dialog {
    &:first-child {
      padding: 100px 150px;
    }

    &__content {
      width: 500px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__title {
      font-weight: bold;
    }

    &__description {
      text-align: center;
    }

    &__icon {
      width: 50px;
      height: 50px;
    }

    &__actions-button {
      margin-right: 20px;
    }
  }
}
</style>
