<template>
  <div class="list-user--trash">
    <sortable-list
      class="page-list"
      :title="$t('list-user.list-title')"
      default-sort="firstname"
      selectable="multiple"
      :items="filter"
      :parameters="trashListParameters"
      :has-pagination="pagination"
      @selected="onSelect"
      @item-click="itemClick"
    />
  </div>
</template>

<script>
import Fuse from "fuse.js";
import { mapActions, mapGetters } from "vuex";
import { LIST_TRASH_USERS } from "@/store/modules/users/users.actions";

export default {
  name: "ListTrashUsers",

  props: {
    pagination: Boolean,
    query: { type: String, default: "" },
    selectedList: { type: Array, default: () => [] },
  },

  data() {
    return {
      confirmationContext: "trash",
      isConfirmModalOpen: false,
      internalList: [],
    };
  },
  computed: {
    ...mapGetters(["isJEPA"]),
    ...mapGetters("users", [LIST_TRASH_USERS]),
    trashListParameters() {
      return [
        {
          name: this.$t("list-user.fullName"),
          key: "fullName",
          class: this.isJEPA
            ? "list-user__fullName alt-item"
            : "list-user__fullName primary-item",
        },
        {
          name: this.$t("form.email"),
          key: "email",
        },
        {
          name: this.$t("form.status"),
          key: "scope",
          format: this.$$filters.formatScope,
        },
        {
          name: "",
          isIcon: true,
          iconFormat: () => "delete",
          iconClass: () => "",
          clickable: true,
          width: "50px",
        },
        {
          name: "",
          isIcon: true,
          iconFormat: () => "restore",
          iconClass: () => "",
          clickable: true,
          width: "50px",
        },
      ];
    },

    filter() {
      if (this.query === "") {
        return this.internalList;
      }

      const fuse = new Fuse(this.internalList, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        keys: ["firstname", "lastname", "email"],
      });

      return fuse.search(this.query).map(item => item.item);
    },
  },

  watch: {
    [LIST_TRASH_USERS]() {
      this.internalList = [...this[LIST_TRASH_USERS]];
    },
  },

  async mounted() {
    this.fetchList(LIST_TRASH_USERS);
  },
  methods: {
    ...mapActions("users", ["fetchList"]),

    onSelect(items) {
      this.$emit("change-list", items);
    },

    itemClick({ item, parameter }) {
      if (this.selectedList.length <= 1) {
        this.$emit("change-list", [item]);
      }
      switch (parameter.iconFormat(item)) {
        case "delete":
          this.$emit("change-action", "delete");
          break;
        case "restore":
          this.$emit("change-action", "restore");
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
