<template lang="html">
  <div>
    <sortable-list
      class="page-list"
      link="/dashboard/setting/users/approve/"
      default-sort="firstname"
      :title="$t('list-user.approve-title')"
      :items="internalList"
      :parameters="unveriFiedlistParameters"
      :has-pagination="pagination"
      @item-click="unverifiedItemClick"
    />
  </div>
</template>

<script>
import { LIST_UNVERIFIED_USERS } from "@/store/modules/users/users.actions";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ListUnverifiedUsers",

  components: {},

  props: {
    pagination: Boolean,
    selectedList: { type: Array, default: () => [] },
  },

  data() {
    return {
      isConfirmModalOpen: false,
      internalList: [],
    };
  },

  computed: {
    ...mapGetters(["isJEPA"]),
    ...mapGetters("users", [LIST_UNVERIFIED_USERS]),

    unveriFiedlistParameters() {
      const defaultParameters = [
        {
          name: this.$t("list-user.fullName"),
          key: "fullName",
          class: this.isJEPA
            ? "list-user__fullName alt-item"
            : "list-user__fullName primary-item",
        },
        {
          name: this.$t("form.email"),
          key: "email",
        },
        {
          name: this.$t("form.origin"),
          key: "origin",
        },
        {
          name: this.$t("form.status"),
          key: "scope",
          format: this.$$filters.formatScope,
        },
        {
          name: this.$t("form.created-at"),
          key: "created_at",
          format: this.$$filters.formatTimestamp,
        },
        {
          name: "",
          isIcon: true,
          iconFormat: () => "delete",
          iconClass: () => "",
          clickable: true,
        },
      ];

      return defaultParameters;
    },

    confirmModalText() {
      const title = "d'un utilisateur";
      return {
        header: `Suppression ${title}`,
        body: items => {
          if (items.length > 1) {
            return `Vous êtes sur le point de supprimer definitivement <b>${items.length}</b> utilisateurs`;
          }

          return `Vous êtes sur le point de supprimer l'utilisateur <b>${items[0].fullName}</b>`;
        },
        question: "Êtes-vous certain de vouloir le faire ?",
      };
    },
  },

  watch: {
    [LIST_UNVERIFIED_USERS]() {
      this.internalList = [...this[LIST_UNVERIFIED_USERS]];
    },
  },

  async mounted() {
    this.fetchList(LIST_UNVERIFIED_USERS);
  },

  methods: {
    ...mapActions("users", ["fetchList"]),

    unverifiedItemClick({ item }) {
      if (this.selectedList.length <= 1) {
        this.$emit("change-list", [item]);
      }
      this.$emit("change-action", "deleteUnverified");
    },
  },
};
</script>

<style scoped lang="scss"></style>
